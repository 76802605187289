<template>
  <!--

  enter="tw-duration-0 tw-ease-out"
    enter-from="tw-opacity-0"
    enter-to="tw-opacity-100"
    leave="tw-duration-0 tw-ease-in"
    leave-from="tw-opacity-100"
    leave-to="tw-opacity-0"

 -->
  <TransitionRoot
    appear
    :show="showMe"
    @before-enter="beforeEnter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @after-leave="afterLeave"
  >
    <Dialog
      class="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto"
      @close="handleClose"
    >
      <div
        ref="dialogRef"
        class="
          tw-flex
          tw-items-center
          tw-justify-center
          tw-min-h-screen
          tw-font-helvetica
        "
      >
        <DialogOverlay
          class="
            tw-fixed tw-inset-0 tw-bg-black tw-opacity-80 tw-transition-all
          "
          :class="{'tw-pointer-events-none': busy}"
        />
        <slot>
          <!-- Use this as content -->
          <div class="tw-card-modal tw-modal tw-mx-4 tw-my-8 tw-z-10 tw-transform-none" :class="[twMaxWidth, {'tw-pointer-events-none': busy}]">
            <slot name="head">
              <div class="tw-card-head-modal">
                <slot name="title">
                  <h4 class="tw-font-normal tw-flex tw-justify-between tw-items-center">
                    <slot name="title-text">Title</slot>
                    <button :disabled="busy" @click="handleClose" class="tw-btn-primary-default tw-w-auto tw-p-0 tw-bg-transparent tw-text-white tw-border-none hover:tw-bg-transparent">
                      <svg-icon name="solid/times"/>
                    </button>
                  </h4>
                </slot>
              </div>
            </slot>
            <div class="tw-card-body-modal with-scroll with-footer-" :class="{'tw-animate-pulse': busy}">
              <slot name="description">
                <p>Description</p>

                <p>
                  Are you sure you want to deactivate your account? All of your
                  data will be permanently removed. This action cannot be undone.
                </p>
              </slot>
            </div>
            <slot name="footer">
              <div class="tw-card-footer">
                <slot name="footer-content"></slot>
              </div>
            </slot>
          </div>
        </slot>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, watch } from "vue";
import { Dialog, DialogOverlay, TransitionRoot } from "@headlessui/vue";
export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionRoot,
  },
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean
    },
    handleClose: {
      type: Function
    },
    twMaxWidth: {
      type: String,
      default: 'tw-max-w-md'
    }
  },
  setup(props) {
    const showMe = ref(false);
    const dialogRef = ref(null);

    setTimeout(() => {
      showMe.value = true;
    }, 0);

    watch(dialogRef, (element, prevElement) => {
      if (element) {
        dialogRef.value = element;
      }
    });

    const beforeEnter = () => {
      if (dialogRef.value) {
        dialogRef.value.classList.add("tw-opacity-0");
        dialogRef.value.classList.add("tw-transition-all");
        dialogRef.value.classList.add("tw-duration-300");
        dialogRef.value.classList.add("tw-ease-out");
      }
    };

    const afterEnter = () => {
      if (dialogRef.value) {
        dialogRef.value.classList.add("tw-opacity-100");
        dialogRef.value.classList.add("tw-transition-all");
        dialogRef.value.classList.add("tw-duration-300");
        dialogRef.value.classList.remove("tw-opacity-0");
      }
    };

    const beforeLeave = () => {
      if (dialogRef.value) {
        dialogRef.value.classList.remove("tw-duration-300");
        dialogRef.value.classList.add("tw-duration-500");
        dialogRef.value.classList.add("tw-opacity-100");
      }
    };

    const afterLeave = (e) => {
      if (dialogRef.value) {
        dialogRef.value.classList.add("tw-opacity-0");
        dialogRef.value.classList.remove("tw-opacity-100");
        dialogRef.value.classList.remove("tw-ease-out");
        dialogRef.value.classList.add("tw-ease-in");
      }
    };

    return {
      showMe,
      beforeEnter,
      afterEnter,
      beforeLeave,
      afterLeave,
      dialogRef,
    };
  },
};
</script>
